// import cessna1 from '../cody-fitzgerald-O0Tr0mrzXLA-unsplash.jpg'
import '../App.css';
// import ContactPage from './ContactPage';

function Home() {
  return (
    <div id="ui-container" className="home-background h-auto min-h-screen grid justify-center items-center">
      <header className="fill w-full fixed  w-full z-20 top-0 start-0  px-4 py-2">
        <nav class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <h1 className="text-2xl font-bold">Ciro Jack Santaniello</h1>
          <ul className="flex space-x-4">
            <li><a href="/" className="hover:bg-gray-200 px-2 py-1 rounded">Home</a></li>
            {/* <li><a href="about" className="hover:bg-gray-200 px-2 py-1 rounded">About</a></li> */}
            <li><a href="membership" className="hover:bg-gray-200 px-2 py-1 rounded">Gallery</a></li>
            {/* <li><a href="contact" className="hover:bg-gray-200 px-2 py-1 rounded">Contact</a></li> */}
            {/* <li><a href="about" className="hover:bg-gray-200 px-2 py-1 rounded">About</a></li> */}
          </ul>
        </nav>
      </header>
      <main className="container mx-auto my-40">
        <section className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* <div className="flex justify-center items-center"><img src={picsComingSoon}/></div> */}
          {/* <div className="flex justify-center items-center"><img className="w-full h-auto aspect-w-16 aspect-h-9" src={cessna1} alt="" /></div> */}
          <div className="flex flex-col justify-center">
            <h2 className="text-3xl font-bold mb-4">photo de Jack</h2>
          </div>
        </section>
      </main>
      <footer className="bg py-4 px-4 text-center">
        <p className="text-gray-600">© 2024 Ciro Jack Santaniello. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;

import '../App.css';
import '../masonry.css';
// import ContactForm from './ContactPage';
import court from '../DSC00051.jpg'
import train from '../DSC00124.jpg'
import bridge from '../DSC00211.jpg'
import cat from '../DSC00229.jpg'
import beachcliff from '../DSC00273.jpg'
import buildingfog from '../DSC00286.jpg'
import tallbuioldingfog from '../DSC00292.jpg'
import riverbuilding from '../DSC00297.jpg'
import multiplebuildingfog from '../DSC00302.jpg'
import astroportrait from '../DSC00321.jpg'
import astrofisheye from '../DSC00339.jpg'
import kitchenplants from '../img20210105_15280920.jpg'
import photo1 from '../IMG_7748.jpg'
import photo2 from '../IMG_7939.jpg'
import photo3 from '../IMG_8063.jpg'
import photo4 from '../IMG_8148.jpg'
import photo5 from '../img018.jpg'
import photo6 from '../img083.jpg'
import photo7 from '../img124.jpg'
import photo8 from '../img193.jpg'
import photo9 from '../img195.jpg'
import photo10 from '../img20220321_19035555.jpg'
import photo11 from '../img20220628_15173623.jpg'
import photo12 from '../img20220628_15231311.jpg'
import photo13 from '../img20220628_15241393.jpg'
import photo14 from '../img20220628_15531100.jpg'
import photo15 from '../img20220628_16354088.jpg'
import photo16 from '../img20220628_17243715.jpg'
import photo17 from '../img20220628_17570617.jpg'
import photo18 from '../img20220727_19322739.jpg'
import photo19 from '../img20220727_19470613.jpg'
import photo20 from '../img20220727_20093142.jpg'
import photo21 from '../img20221023_16050219.jpg'
import photo22 from '../img20221023_17035596.jpg'
import photo23 from '../img20221023_18290053.jpg'

function Membership() {
    return (
        <div id="ui-container" className="h-auto min-h-screen grid justify-center items-center">
        <header className="fill w-full fixed w-full z-20 top-0 start-0 bg-whited px-4 py-2">
          <nav class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <h1 className="text-2xl font-bold">Ciro Jack Santaniello</h1>
            <ul className="flex space-x-4">
              <li><a href="/" className="hover:bg-gray-200 px-2 py-1 rounded">Home</a></li>
              <li><a href="membership" className="hover:bg-gray-200 px-2 py-1 rounded">Gallery</a></li>
              {/* <li><a href="contact" className="hover:bg-gray-200 px-2 py-1 rounded">Contact</a></li> */}
              {/* <li><a href="about" className="hover:bg-gray-200 px-2 py-1 rounded">About</a></li> */}
            </ul>
          </nav>
        </header>
      
        <main className="container">
          <h2 className="text-3xl font-bold my-4 mb-4">Gallery</h2>
          <div className="gallery-container">
            <ul className="masonry-gallery">
              <li><img src={court} alt=""/></li>
              <li><img src={train} alt=""/></li>
              <li><img src={bridge} alt=""/></li>
              <li><img src={cat} alt=""/></li>
              <li><img src={beachcliff} alt=""/></li>
              <li><img src={buildingfog} alt=""/></li>
              <li><img src={tallbuioldingfog} alt=""/></li>
              <li><img src={riverbuilding} alt=""/></li>
              <li><img src={multiplebuildingfog} alt=""/></li>
              <li><img src={astroportrait} alt=""/></li>
              <li><img src={astrofisheye} alt=""/></li>
              <li><img src={kitchenplants} alt=""/></li>
              <li><img src={photo1} alt=""/></li>
              <li><img src={photo2} alt=""/></li>
              <li><img src={photo3} alt=""/></li>
              <li><img src={photo4} alt=""/></li>
              <li><img src={photo5} alt=""/></li>
              <li><img src={photo6} alt=""/></li>
              <li><img src={photo7} alt=""/></li>
              <li><img src={photo8} alt=""/></li>
              <li><img src={photo9} alt=""/></li>
              <li><img src={photo10} alt=""/></li>
              <li><img src={photo11} alt=""/></li>
              <li><img src={photo12} alt=""/></li>
              <li><img src={photo13} alt=""/></li>
              <li><img src={photo14} alt=""/></li>
              <li><img src={photo15} alt=""/></li>
              <li><img src={photo16} alt=""/></li>
              <li><img src={photo17} alt=""/></li>
              <li><img src={photo18} alt=""/></li>
              <li><img src={photo19} alt=""/></li>
              <li><img src={photo20} alt=""/></li>
              <li><img src={photo21} alt=""/></li>
              <li><img src={photo22} alt=""/></li>
              <li><img src={photo23} alt=""/></li>
            </ul>
          </div>
        </main>
      </div>
    )
}
export default Membership;  